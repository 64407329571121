import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {User} from "./models/user";

@Injectable()
export class UserService {
  sponsor: User[] = [
    { id: 11, login: 'DrNice' },
    { id: 12, login: 'Narco' }
  ];

  sponsors = [
  {
    "login":"tds",
    "name":"Ambrish Agrawal",
    "id":1,
    "avatar_url":"https://avatars0.githubusercontent.com/u/1?v=4",
    "type":"Sponsor",
    "site_admin":false
  },
  {
    "login":"cks",
    "name":"Vivek Vishwakarma",
    "id":2,
    "avatar_url":"https://avatars0.githubusercontent.com/u/1?v=4",
    "type":"Sponsor",
    "site_admin":false
  }
];
  // define our class properties. apiUrl is what we need
  // usually you could get this from an environment file
  apiUrl = 'https://api.github.com/users';

  // inject the HttpClient as http so we can use it in this class
  constructor(private http: HttpClient) {}

  // return what comes back from this http call
  getUsers(): User[] {
    return this.sponsor;
    //return this.http.get(`${this.apiUrl}?per_page=10`);
  }
  getUser(username: string) {
    return this.http.get(`${this.apiUrl}/${username}`);
  }
}
